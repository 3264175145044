import jsonFetcherCommon, { UnAuthorizedError } from "../../../common/lib/jsonFetcher/jsonFetcher";

function jsonFetcher(url, params = {}) {
	return jsonFetcherCommon(url, params)
		.catch(e => {
			if (e instanceof SyntaxError) {
				throw new Error("Something went wrong. Please try again later.")
			}
			if (e instanceof UnAuthorizedError) {
				window.location = "/#loggedOut"
			}
			throw e
		})
}



export default jsonFetcher