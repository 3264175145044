import React, { useState, useEffect } from 'react'
import ResponsiveUtils from '../Responsivness/Responsivness';

function FadeSlideShow({ photos, style, delay = 3, fadeDelay = .3 }) {
	const [isLoading, setIsLoading] = useState(true);
	const [currentIndex, setCurrentIndex] = useState(0);

	useEffect(() => {
		setTimeout(() => {
			setIsLoading(true)
		}, (delay - fadeDelay) * 1000)
		setTimeout(() => {
			setCurrentIndex((currentIndex + 1) % photos.length)
		}, delay * 1000)
		setTimeout(() => {
			setIsLoading(false)
		}, fadeDelay * 1000)
	}, [currentIndex])
	return (
		<div
			style={{
				width: "100%",
				height: "100%",
				background: `url(${photos[currentIndex]}) center center no-repeat / ${
					ResponsiveUtils.choose({ mobile: "cover", other: "contain" })
				}`,
				opacity: isLoading ? 0 : 1,
				transition: `opacity ${fadeDelay}s`,
				...style,
			}}>

		</div>
	)
}

export default FadeSlideShow
