export const loadingActions = {
	loading: "LOADING",
	start: "START_LOADING",
	stop: "STOP_LOADING",
}

export const mainActions = {
	changeLanguage: "CHANGE_LANGUAGE",
}

export function toggleLoadingAction(start) {
	return {
		type: start ? loadingActions.start : loadingActions.stop,
		//payload: start
	}
}

export function changeLanguage(locale) {
	return {
		type: mainActions.changeLanguage,
		payload: locale
	}
}


export const notificationActions = {
	topMessage: "TOP_MESSAGE"
}

/**
 * 
 * @param {string} message 
 * @param {success, warning, error} status 
 */
export function showTopMessage(message, status) {
	return {
		type: notificationActions.topMessage,
		message, status
	}
}