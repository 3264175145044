export class UnAuthorizedError extends Error {
	constructor(...params) {
		super(...params);
		if (Error.captureStackTrace) {
			Error.captureStackTrace(this, UnAuthorizedError);
		}
		this.name = 'UnAuthorizedError';
		this.date = new Date();
	}

}

export default function jsonFetcher(url, params = {}) {
	return fetch(url, {
		...params,
		...((params.method && params.method.toLowerCase() === "post")
			&& { headers: { ...params.headers, "Content-Type": "application/json" } }),
		...(params.body && { body: JSON.stringify(params.body) })
	}).then(r => {
		return r.json()
			.then(data => {
				if (!r.ok) {
					if (r.status === 401) {
						throw new UnAuthorizedError(data.message)
					}
					throw new Error(data.message)
				}
				return data;
			})

	})
}