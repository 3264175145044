export const LANGUAGES = {
	"fr": "Français",
	"en": "English"
}

export const MESSAGES = {

	"comingSoon": {
		en: "Coming soon",
		fr: "Bientôt",
	},
	"subscribeNow": {
		en: "Subscribe now",
		fr: "Abonnez vous",
	},
	"allRightsAreReserved": {
		en: "All rights reserved",
		fr: "Tous droits réservés"
	}

};

