import React from 'react'

function Logo({
	align = "left center",
	logoOnly = true,
	width,
	height,
	style,
	...props }) {

	const url = logoOnly
		? "logo"
		: ""

	return (
		<div
			{...props}
			style={{
				width: width ? width : "100%",
				height: height ? height : (logoOnly ? width / 1.2 : "80px"),
				background: `url(/assets/logo/${url}.png) ${align} / cover`,
				...style,
			}}>
		</div >
	)
}

export default Logo
