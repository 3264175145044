import { put, takeLatest } from "redux-saga/effects"
import { showTopMessage } from "./actions";
import { loginActions } from "../../Auth/actions";


function* process(action) {
	yield put(showTopMessage(action.message, action.status));
}

function* logoutSaga() {
	yield takeLatest(loginActions.logout.success, process);
}
export default logoutSaga