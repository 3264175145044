import { createPromiseActions } from "../Store/createPromiseActions";
import { fetchAction } from "../Store/common/fetchSaga"


export const loginActions = createPromiseActions({
	login: "LOGIN_FETCH",
	logout: "LOGOUT_FETCH"
})

export const login = (data) => fetchAction(
	loginActions.login,
	"/api/login",
	{
		method: "post",
		body: data
	}
)

export const logout = () => fetchAction(
	loginActions.logout,
	"/api/logout"
)