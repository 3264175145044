import { loadingActions, notificationActions, mainActions } from "./actions";
import { message } from "antd";

export default function mainReducer(state = {}, action) {
	switch (action.type) {

	case loadingActions.start:
	case loadingActions.stop:
		return {
			...state,
			isLoading: action.type === loadingActions.start
		}
	case notificationActions.topMessage:
		message[action.status](action.message, 2)
		return {
			...state,
			topMessage: {
				message: action.message,
				status: action.status
			}
		}

	case mainActions.changeLanguage:
		return {
			...state,
			language: action.payload
		}

	default:
		return state;
	}
}