import { put, call, takeLatest } from "redux-saga/effects"
import { toggleLoadingAction } from "./actions";
import jsonFetcher from "../../lib/jsonFetcher/jsonFetcher";

export const fetchAction = (actions, url, payload) => {
	return {
		type: actions.fetch,
		url,
		payload,
		actions,
		sagaFetch: true,
	}
}

function* fetchData(action) {
	try {
		yield put(toggleLoadingAction(true));
		const data = yield call(jsonFetcher, action.url, action.payload)
		yield put({
			type: action.actions.success,
			...data
		});
	} catch (e) {
		yield put({
			type: action.actions.fail,
			message: e.message
		})
	} finally {
		yield put(toggleLoadingAction(false));
	}
}

function* fetchSaga() {
	yield takeLatest(action => action.sagaFetch, fetchData);
}
export default fetchSaga