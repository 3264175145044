import React from 'react'

import en from 'react-intl/locale-data/en';
import fr from 'react-intl/locale-data/fr';
import { addLocaleData, IntlProvider } from 'react-intl';
import { MESSAGES } from './messages';
import useLanguage from '../../common/hooks/useLanguage/useLanguage';

addLocaleData([...en, ...fr]);

function getMessages(language) {
	return Object.entries(MESSAGES).reduce((res, [key, next]) => {
		res[key] = next[language]
		return res
	}, {})
}

function MyIntlProvider(props) {
	const [locale, short] = useLanguage()

	console.log(locale, short)

	return (
		<IntlProvider {...props}
			locale={locale}
			messages={getMessages(short)} />
	)
}

export default MyIntlProvider
