import React from 'react';
import { Switch, Route } from "react-router-dom"
import { connect } from 'react-redux';
import TopProgress from '../common/components/TopProgress/TopProgress';
import { useDebounce } from 'use-debounce';
import DisconnectionAlert from '../common/components/DisconnectionAlert/DisconnectionAlert';
import WelcomePage from './WelcomePage/WelcomePage';
import Footer from './components/Footer/Footer';

function Routes({ isLoading }) {
	const [debouncedIsLoading] = useDebounce(isLoading, 500)

	return (
		<>
			<DisconnectionAlert>
				<TopProgress isLoading={debouncedIsLoading} />
				<div style={{ transition: "opacity .3s .2s" }}>
					<Switch>
						<Route path="/" component={WelcomePage} />
					</Switch>
				</div>
				<Footer />
			</DisconnectionAlert>
		</>
	);
}

export default connect(
	({ main }) => ({ isLoading: main.isLoading, topMessage: main.topMessage })
)(Routes);
