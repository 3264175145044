import React from 'react'
import Wrapper, { RowWrapper, ColWrapper } from '../components/Wrapper/Wrapper';
import Logo from '../components/Logo/Logo';
import Flex from '../../common/components/Flex/Flex';
import useWindowSize from '../../common/hooks/useWindowSize/useWindowSize';
import FadeSlideShow from '../../common/components/FadeSlideShow/FadeSlideShow';
import Title from '../../common/components/Title/Title';
import { FormattedMessage } from "react-intl"


function WelcomePage() {

	const size = useWindowSize()

	return (
		<RowWrapper style={{ padding: 40 }}>
			<ColWrapper fluid sm={{ span: 12, offset: 0 }}>
				<Flex style={{ height: "100%" }}>
					<div style={{
						padding: 20,
						textAlign: "center",
						backgroundColor: "white"
					}}>
						<Logo width={150} />
						<Title
							wide
							size={10}
							style={{ opacity: 1 }}
							uppercase sub>
							<FormattedMessage id="comingSoon" />
						</Title>
					</div>
				</Flex>
			</ColWrapper>
			<ColWrapper fluid sm={{ span: 12, offset: 0 }}>
				<FadeSlideShow
					style={{
						position: 'absolute',
						zIndex: -1
					}}
					photos={Array(13).fill(1).map((_, index) => `/assets/testing/photos/${index + 1}.${index + 1 < 3 ? "PNG" : "jpg"}`)} />
			</ColWrapper>
		</RowWrapper>
	)
}

export default WelcomePage
